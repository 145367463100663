<template>
  <div
    v-if="notificationData"
  >

    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card>
            <b-card-header>
              <!-- title and subtitle -->
              <div>
                <b-card-title class="mb-1">
                  {{ notificationData.title }}
                </b-card-title>
                <b-card-sub-title>{{ notificationData.message }}</b-card-sub-title>
              </div>
              <!--/ title and subtitle -->

              <!-- badge -->
              <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
                <h5 class="font-weight-bolder mb-0 mr-1">
                  {{ dateFormat(notificationData.created_at) }}
                </h5>

              </div>
              <!--/ badge -->
            </b-card-header>
            <!-- eslint-disable vue/no-v-html -->
            <b-card-body>
              <div
                class="blog-content"
                v-html="notificationData.param1"
              />
            </b-card-body>
          </b-card>
        </b-col>
        <!--/ blogs -->

        <!-- Leave a Blog Comment -->

        <!--/ Leave a Blog Comment -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->

  </div>
</template>

<script>
import {

  BCard, BRow, BCol, BCardHeader, BCardTitle, BCardSubTitle, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import notificationStoreModule from './notificationStoreModule'

export default {
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCardBody,
    BCol,
    BCardTitle,
    BCardSubTitle,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search_query: '',
      commentCheckmark: '',
      blogDetail: [],
      blogSidebar: {},
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.fetchData()
    },
  },
  methods: {
    dateFormat(date) { return this.$moment(date).fromNow() },
  },
  setup() {
    const notificationData = ref(null)

    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-notification'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, notificationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    const fetchData = (() => {
      notificationData.value = null
      store.dispatch('app-notification/fetchSingleNotification', { id: router.currentRoute.params.id })
        .then(response => {
          notificationData.value = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            notificationData.value = undefined
          }
        })
    })
    fetchData()
    return {
      notificationData,
      fetchData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
